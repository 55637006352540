import React, { useState } from "react";
import styled from "styled-components";
import { Grid, ButtonBase, Modal, Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

import { Layout, TopNavigation, FloatingPlayIcon } from "components";
import { VideoDetailNavigation } from "components/TopNavigation";
import VideoPlayer from "components/VideoPlayer";
import cover from "./images/poster.jpg";
import image1 from "./images/image1.jpg";
import image2 from "./images/image2.jpg";
import image3 from "./images/image3.jpg";
import image4 from "./images/image4.jpg";
import image5 from "./images/image5.jpg";
import image6 from "./images/image6.jpg";
import image7 from "./images/image7.jpg";

const isLive = false;

const seo = {
  title: "Quiet Minds Silent Streets",
  description: `Quiet Minds Silent Streets introduces viewers to a community that has been chronically affected by gun violence. This thoughtful, eye-opening documentary asks whether they can find a new way forward with a relatively simple technique: mindfulness and meditation.`,
};

const ParaLg = styled.p`
  font-size: 1.5em;
  font-weight: 600;
  text-align: left;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    position: absolute;
    max-width: 60vw;
    left: 4rem;
    font-size: 1.5em;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 50vw;
    left: 8rem;   font-size: 1.75em;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    max-width: 40vw;
    left: 18rem;
  }
  ${({ theme }) => theme.breakpoints.up("xl")} {
    left: 20rem;
  }
`;

const PageContainer = styled.div`
  background-color: #000;
  color: #fff;
  font-size: 1rem;
`;

const Quote = styled.p`
  font-size: 1.35rem;
  margin-bottom: 1.5rem;
`;

const SectionPara = styled.p`
  text-align: center;
  margin: 0;
`;

const useStyles = makeStyles((theme) => ({
  coverImage: {
    maxHeight: "calc(100vh - 65px)",
    objectFit: "cover",
    objectPosition: "top",
  },
  publicHearingImage: {
    maxHeight: "90vh",
    maxWidth: "50vw",
    objectFit: "contain",
  },
  publicHearingImageContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "end",
    },
  },
  sectionPadding: {
    padding: "1rem",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      padding: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      padding: "2rem 4rem",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "2rem 6rem",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "2rem 8rem",
    },
  },
  singleImage: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "50vw",
      maxHeight: "30vh",
    },
  },
  gridImages: {
    maxHeight: "50vw",
  },
  tiltedImageContainer: {
    padding: "2rem 0",
  },
  tiltedImage: {
    [theme.breakpoints.up("md")]: {
      margin: "3rem 0",
      transform: "rotate(-10deg) translate(-55px, -15px)",
    },
  },
  tiltedCaption: {
    textAlign: "left",
    fontSize: "1.5em",
    margin: 0,
    fontWeight: 600,
  },
  fullsizeImg: {
    maxHeight: "80vh",
    objectFit: "cover",
  },
  textLg: {
    fontSize: "1.5em",
    fontWeight: 600,
  },
  buttonLg: {
    backgroundColor: "#c0c6b5",
    marginBottom: "3rem",
    padding: "1rem 1.5rem",
    color: "#000",
    textDecoration: "none",
  },
  paper: {
    position: "absolute",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    width: "80vw",
    [theme.breakpoints.between(0, 600)]: {
      top: `50%`,
      left: `50%`,
      width: "100vw",
    },
  },
  modal: {
    backgroundColor: "black",
  },
  menuIcon: {
    color: theme.typography.color,
  },
  textRight: {
    textAlign: "right",
    padding: "0 4rem",
    cursor: "pointer",
  },
  noDecoration: {
    textDecoration: "none",
  },
  centered: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
    },
  },
  premieringNotif: {
    padding: '1rem',
    border: '2px solid #fff',
    position: 'absolute',
    top: '50%',
    left: 'calc(50% - 125px)',
    borderRadius: 4,
    cursor: 'default'
  }
}));

const Sentence = ({ location }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleVideo = () => {
    setOpen(true);
  };

  return (
    <Layout location={location} seo={seo}>
      <TopNavigation />
      <PageContainer>
        <Box style={{ position: "relative", cursor: "pointer" }}>
          <FloatingPlayIcon
            float
            onClick={handleVideo}
            height={100}
            width={100}
          />
          <img
            src={cover}
            className={`w-full ${classes.coverImage}`}
            alt="The sentence of Michael Thompson"
          />
        </Box>
        <Grid
          container
          alignItems="center"
          className={classes.sectionPadding}
          justifyContent="space-evenly"
        >
          {/* <Grid item md={3} sm={4} hidden /> */}
          <Grid item xs={12} className={classes.centered}>
            <ParaLg>
            Quiet Minds Silent Streets introduces viewers to a community that has been
            chronically affected by gun violence. This thoughtful, eye-opening
            documentary asks whether they can find a new way forward with a relatively simple technique: mindfulness
            and meditation.
            </ParaLg>
            <div className={classes.publicHearingImageContainer}>
              <img
                src={image1}
                alt=""
                className={classes.publicHearingImage}
              />
            </div>
          </Grid>
        </Grid>
        <Container maxWidth="lg">
          <div className={classes.sectionPadding}>
            <SectionPara>
              Quiet Minds Silent Streets centers around a youth group in Malton, Ontario: a neighborhood in the Greater Toronto Area that has
              been plagued by gun violence. In 2019, Malton was scarred by a horrific mass shooting that took the life of 17-year-old
              Jonathan Davis. Grappling with grief and fear, the youth in the community began looking for ways to cope.<br/><br/>
              Janice Lowe, a high school teacher and youth mentor in the community, came across a powerful TEDxTalk by Director X on the
              science behind meditation entitled, Message To The Man Who Shot Me. Lowe showed her students the video and proposed an
              idea: what if we meditate together?
            </SectionPara>
          </div>
        </Container>
        <div className={classes.sectionPadding}>
          <img src={image2} alt="Michael" className={classes.singleImage} />
        </div>
        <Container maxWidth="lg">
          <div className={classes.sectionPadding}>
            <SectionPara>
            The students then decided to reach out to Director X through the organization he co-founded, Operation Prefrontal Cortex; a 
            platform dedicated to educating people on the power of mindfulness and meditation, to reduce the incidents of gun and mass
            violence in communities. The film follows their journey to heal the community as told from the narrative of the students, teachers,
            and community leaders.
            </SectionPara>
          </div>
        </Container>
        <div className={classes.sectionPadding}>
          <Grid
            container
            justifyContent="space-evenly"
            style={{flexWrap: 'nowrap'}}
          >
            <Grid item sm={6} style={{ padding: '.2rem' }}>
              <img
                src={image3}
                alt="Michael"
                className={`${classes.gridImages} w-full`}
              />
            </Grid>
            <Grid item sm={6} style={{ padding: '.2rem' }}>
              <img
                src={image4}
                alt="Court"
                className={`${classes.gridImages} w-full`}
              />
            </Grid>
            <Grid item sm={6} style={{ padding: '.2rem' }}>
              <img
                src={image5}
                alt="Court"
                className={`${classes.gridImages} w-full`}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.sectionPadding}>
          <Quote><p>
          The film showcases that meditation can have lasting and profound effects on one’s mental health and it should be accessible to all.
          Headspace chose to be a partner on this film because Headspace is dedicated to spreading awareness of the power of meditation
          and mindfulness as a tool and a resource. Studies show that victims and members of communities affected by gun violence experience
          increases in posttraumatic stress symptoms, depression, and other signs of psychological concerns. Other studies have found
          that, after previous mass shootings, youth felt less safe and more fearful. These findings, many of which are unsurprising, make
          it more important to take urgent action to prevent these shootings, particularly in light of the ongoing youth mental health crisis.</p><p>
          Headspace wants to be a part of the solution and is committed to helping people by making the app free and accessible to
          teachers, students, and healthcare professionals. Another way Headspace is getting involved is on the community and government level.
          In Fall of 2023, Headspace launched a partnership with the City of Hartford, working with City leaders and residents to advance mental
          health equity through collective efforts designed to strengthen community bonds, scale compassion, and curate resources to enhance
          community healing. Headspace believes bettering ourselves and those around us is the way to ensure a more peaceful,
          compassionate world.</p>
          </Quote>
        </div>
        <Grid
          container
          className={classes.tiltedImageContainer}
          alignItems="center"
        >
          <Grid item md={6}>
            <img
              src={image6}
              alt="Michael file image"
              className={`${classes.tiltedImage} w-full`}
            />
          </Grid>
          <Grid item md={6} className={classes.sectionPadding}>
            <p className={classes.tiltedCaption}>
            Quiet Minds Silent Streets
            explores the transformative
            potential of meditation,
            delving into its role as a
            crucial tool to combat the
            impact of trauma
            and violence.
            </p>
          </Grid>
        </Grid>
        <img
          src={image7}
          alt="quiet-minds-silent-streets"
          className={`w-full ${classes.fullsizeImg}`}
        />
      </PageContainer>
      <Modal
        className={classes.modal}
        disableBackdropClick={true}
        open={open}
        onClose={() => {
          // trackCustomEvent({
          //   category: "video",
          //   action: "Close video",
          //   label: "Exit",
          // });
          setOpen(false);
        }}
        aria-labelledby="quiet-minds-silent-streets"
      >
        <div className={classes.paper}>
          <VideoPlayer
            playerId="cDnha7c4"
            mediaId="jOKy6DTG"
            url="https://cdn.jwplayer.com/manifests/jOKy6DTG.m3u8"
            autostart={true}
            title="Quiet Minds Silent Streets"
            setData={() => {}}
            open={open}
          />
        </div>
      </Modal>
      {(open && (
        <VideoDetailNavigation
          onClose={() => setOpen(false)}
          title="Quiet Minds Silent Streets"
        />
      )) ||
        null}
    </Layout>
  );
};

export default Sentence;
